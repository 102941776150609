.btn
  svg
    path
      fill: currentColor

  &.btn-breadcrumb, &.btn-dots
    font-size: 1rem

    &.link, &.dropdown-toggle
      position: relative
      padding-right: 1.85rem
    .material-icons
      position: absolute
      top: 6px
      right: 0px

  &.btn-breadcrumb
    &:hover
      background: $gray-200
    &:focus
      background: rgba($primary, 0.15)
      color: darken($primary, 15%)

  &.btn-inline
    padding: 0.15rem 0.5rem
    font-size: 0.875rem
    line-height: 1.5
    border-radius: 0.2rem

    &:focus
      box-shadow: none

  &.btn-cyan, &.btn-red, &.btn-yellow, &.btn-green
    color: $white

.kl-more
  .btn-link
    padding: 0
    line-height: 0
    margin-top: -3px


    