.folder-content
  height: calc(100vh - 69px)
  overflow-x: auto

  #file-input
    display: none


.item-content
  height: calc(100vh - 69px)
  overflow: hidden
  padding: 10px
  text-align: center

  .item-wrapper
    max-width: 100%
    max-height: 100%

  img 
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2)
    max-width: 100%
    max-height: 100%

.item-link
  width: 48px  

  &.back, &.next
    a 
      display: block
      width: 48px
      height: 48px
      svg
        path
          fill: $gray-500

      &:hover
        svg
          path
            fill: $gray-900

    .disabled
      svg
        path
          fill: $gray-200