.navbar-app
  width: 185px

  .navbar-apps
    position: relative
    margin-right: 4px

    .material-icons
      position: relative
      top: -1px

  .navbar-brand
    display: inline-block

  .logo
    margin-right: 11px
    float: left

    svg
      height: 24px
      width: 24px

  .klind
    svg
      width: 40px
      height: 18px

.kl-navbar 
  background: $white
  border-bottom: 1px solid $gray-300
  height: 69px
        

#user-nav
  .nav-link
    &.notification
      svg
        path
          fill: $gray-800

      .badge
        left: 32px