// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "../stylesheets/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// @import "../../assets/fonts/material-icons/material-icons";

@import '../stylesheets/partials/form';
@import '../stylesheets/partials/layout';

@import '../stylesheets/partials/alert';
@import '../stylesheets/partials/avatar';
@import '../stylesheets/partials/badge';
@import '../stylesheets/partials/button';
@import '../stylesheets/partials/cookie';
@import '../stylesheets/partials/dropdown';
@import '../stylesheets/partials/folder';
@import '../stylesheets/partials/footer';
@import '../stylesheets/partials/list';
@import '../stylesheets/partials/modal';
@import '../stylesheets/partials/table';
@import '../stylesheets/partials/section';
@import '../stylesheets/partials/subnav';

@import '../stylesheets/partials/appbar';
@import '../stylesheets/partials/sidebar';
@import '../stylesheets/partials/navbar';

@import "../stylesheets/actiontext.scss";

.position-left {
  position: absolute;
  right: 20px;
  margin-top: 4px;
}

// Bootstrap-Form not supporting new classes

.sr-only {
  @include visually-hidden();
}

.lh-xs {
  line-height: 0.875rem;
}

.lh-0 {
  line-height: 0 !important;
}

.material-icons {
  &.size-18 {
    height: 18px;
    width: 18px;
  }
}