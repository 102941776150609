.cookie-note
  background: $white
  box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.2)
  display: none
  position: fixed
  bottom: 0
  width: 100%


.show-cookie-note 
  padding-bottom: 118px
  
  .cookie-note
    display: block