// html, body
//   height: 100%
//   min-height: 100%

#wrapper
  margin-top: 69px

  #page-content-wrapper
    width: 100%
    
  #sidebar-wrapper
    background-color: $gray-200
    min-width: 260px
    width: 260px
    min-height: calc(100vh - 69px)
    position: fixed
    right: -260px

  &.animate
    #page-content-wrapper, #sidebar-wrapper
      transition: all 0.5s ease-in-out

  &.sidebar-open
    #page-content-wrapper
      width: calc(100% - 260px)

    #sidebar-wrapper
      right: 0

  

// .container-grid
//   display: grid
//   grid-template-columns: 260px auto 260px
//   min-height: 100%

//   .left-grid-item
//     border-right: 1px solid $gray-300

//   .right-grid-item
//     border-left: 1px solid $gray-300

// .right
//   float: right

.uppercase
  text-transform: uppercase