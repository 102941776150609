label 
  font-size: 14px

.invalid-feedback
  margin-left: .1rem

  .small
    margin-left: 1rem

  label
    color: #343a40
    font-size: 0.875rem
    font-weight: 600

.permission-form
  .form-group
    margin-bottom: 0

  .btn
    max-height: 35px

.comments-form
  .trix-button-row, .trix-button
    display: none

.kld-checkbox
  height: 0px
  width: 0px

.kld-checkbox + label
  position: relative
  display: flex
  font-size: inherit
  align-items: center
  cursor: pointer
  transition: color 250ms cubic-bezier(.4,.0,.23,1)

  > span
    display: flex
    justify-content: center
    align-items: center
    margin-right: 12px
    background: transparent
    border: 2px solid $gray-900
    border-radius: 50%
    height: 20px
    width: 20px
    transition: all 250ms cubic-bezier(.4,.0,.23,1)

.kld-checkbox:checked + label 
  text-decoration: line-through

  > span
    border: 10px solid $teal
    animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1)

    &:before
      content: ""
      position: absolute
      top: 7px
      left: 3px
      border-right: 3px solid transparent
      border-bottom: 3px solid transparent
      border-color: $gray-900
      transform: rotate(45deg)
      transform-origin: 0% 100%
      animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards

@keyframes shrink-bounce
  0%
    transform: scale(1)
  33%
    transform: scale(.85)
  100%
    transform: scale(1) 

@keyframes checkbox-check
  0%
    width: 0
    height: 0
    transform: translate3d(0,0,0) rotate(45deg)
  33%
    width: 7px
    height: 0
    transform: translate3d(0,0,0) rotate(45deg)
  100%
    width: 7px
    height: 13px   
    transform: translate3d(0,-.5em,0) rotate(45deg)

  // https://codepen.io/dylanraga/pen/Qwqbab