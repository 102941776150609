#sidebar-wrapper
  .list-group
    border-top: 1px solid rgba(0, 0, 0, 0.125)
    
    .list-group-item
      button
        background: transparent
        border: 0
        padding: 0        

      a, button
        color: $gray-800

        &:hover
          text-decoration: none

  .section
    background: none
    box-shadow: none
    padding: 0

    &.card
      background: $white

.mainbar, #infobar
  position: relative

  .sticky
    position: fixed
    height: calc(100vh - 69px)

  .nav-link
    color: $gray-900
    font-size: 0.875rem
    font-weight: 600
    line-height: 30px
    border-radius: 4px
    padding: 0.5rem

    .material-icons
      position: relative
      top: -2px
      width: 20px
      
      path 
        fill: $gray-600

    &:hover
      background: $gray-200

  @each $color, $value in $theme-colors
    .nav-#{$color}
      .active
        background: rgba($value, 0.15)
        color: $value
        .material-icons
          path
            fill: $value

.mainbar
  min-width: 200px
  max-width: 200px
  padding: 5px

  .mainbar-sticky
    position: fixed
    top: 69
    height: calc(100vh - 69px)
    overflow-x: hidden
    overflow-y: auto // Scrollable contents if viewport is shorter than content.
    width: 190px

  .nav-item
    max-width: 190px

  .nav-link
    .material-icons
      margin-right: 0.25rem

#infobar
  width: 45px
  min-width: 45px

  .info, .nav
    position: absolute
    height: calc(100vh - 69px)
   
  .nav
    background: $white
    right: -45px
    padding: 5px
    width: 45px

    .nav-link
      padding: 1px 1px 1px 6px

  .info
    left: 0
    width: 260px

    .section
      background: none
      box-shadow: none

      dl 
        margin-bottom: 0

  &.animate
    transition: all 0.5s ease-in-out

    .nav
      transition: all 0.5s ease-in-out

  &.open
    width: 305px
    min-width: 305px

    .nav
      right: -305px
    .info
      // display: inline
      // width: 260px

// #wrapper.sidebar-open
//   #infobar
//     .sticky
//       right: 260px

.info
  dl
    dt
      color: $gray-500
      font-weight: 600

    dd
      color: $gray-700

  ul
    margin: 0
    padding: 0
    
    li
      list-style: none
      line-height: 0.875rem

#notifications
  .unread 
    border-left: 3px solid $red
    

@supports ((position: -webkit-sticky) or (position: sticky))
  .mainbar-sticky
    position: -webkit-sticky
    position: sticky

