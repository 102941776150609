.list
  padding: 1rem

  .list-item
    font-size: .875rem

  &.grid
    display: flex
    align-items: flex-start
    flex-direction: row
    flex-wrap: wrap

    .list-item
      width: 15%
      margin: 0 1rem 1rem 0
      position: relative

      .folder
        background-color: $white
        border: 1px solid $white
        border-radius: 7px
        box-shadow: 0 6px 12px 0 rgba(0,0,0,.05)
        padding: 5px 10px 
        position: relative

        .thumb
          position: relative
          width: 100%
          padding-top: 100%
          text-align: center

          .thumb-item, .upload
            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0

          .thumb-item
            display: flex
            align-items: center
            justify-content: center

          .upload
            background: $white
            opacity: 0.9

          .material-icons
            width: 90%
            height: 90%
            path
              fill: rgba($cyan, 0.5)

          img 
            max-width: 100%
            max-height: 100%

        .name
          color: $gray-600
          font-weight: 500
          padding-right: 24px

        .details
          color: $gray-400
          font-weight: 600
          font-size: 0.8rem
          text-transform: uppercase

      .kl-more
        position: absolute
        bottom: 21px
        right: 5px

      &:hover 
        a 
          text-decoration: none

        .folder
          box-shadow: 0 6px 12px 0 rgba(0,0,0,.175)
                  
.path-list
  margin: 0
  padding: 0

  span::before
    content: '/'

.permission-list
  padding: 0
  margin: 0

  .pl-2
    line-height: 28px  

  a
    .material-icons  
      width: 20px
      position: relative
      top: -1px
      margin-right: 0.25rem

      path
        fill: $gray-600