.kl-breadcrumb, .kl-more
  display: inline-block
  .dropdown-item
    padding-top: 0.5rem
    padding-bottom: 0.5rem
    .material-icons
      position: relative
      top: -1px
      margin-right: 0.25rem
      width: 20px
      height: 20px

      path
        fill: $gray-600

    &:active
      background-color: rgba($cyan, 0.15)
      color: $cyan

      .material-icons
        color: $cyan


  .dropdown-toggle::after
    display: none

.btn-inline
  &::after
    display: none

