#appbar, .overlay
  position: fixed
  top: 0
  left: 0

#appbar
  background-color: $white
  min-width: 250px
  max-width: 250px
  height: 100vh
  left: -250px
  transition: all 0.5s ease-in-out
  padding: 21px 0 0 16px
  z-index: 9999
  

.overlay
  display: none
  position: fixed
  width: 100vw
  height: 100vh
  background: rgba(0, 0, 0, 0.5)
  opacity: 0
  transition: all 0.5s ease-in-out
  z-index: 9998

#appbar
  .logo
    margin-right: 11px
    float: left

    svg
      height: 24px
      width: 24px

  .klind
    svg
      width: 40px
      height: 18px

      &.material-icons
        width: auto
        height: auto

  .app
    display: block
    height: 30px

    .material-icons
      float: left
      margin-right: 13px
      
    .cloud
      svg
        width: 40px
        height: 18px

.appbar-open
  #appbar
    left: 0px 

  .overlay
    display: block
    opacity: 1