.img-circle
  max-width: 180px
  max-height: 180px
  border-radius: 50%

.img-user-small
  height: 28px
  width: 28px

.img-user-thumb
  height: 36px
  width: 36px

.img-user-medium-thumb
  height: 64px
  width: 64px

.img-user-big-thumb
  height: 128px
  width: 128px

.avatar
  background: $red
  color: $white
  text-align: center
  text-transform: uppercase
  padding: 1px 0px 0 1px

  &.img-user-big-thumb
    font-size: 64px
    line-height: 124px
  
  &.img-user-medium-thumb
    font-size: 30px
    line-height: 60px

  &.img-user-thumb
    font-size: 16px
    line-height: 35px

  &.img-user-small
    font-size: 13px
    line-height: 27px