.modal-body
  padding: 0 1rem 1rem

.modal-footer
  justify-content: flex-start

.close
  line-height: 0.8

  &:focus
    outline: none