.subnav
  background: $white
  border-bottom: 1px solid $gray-300
  display: flex
  width: 100%
  z-index: 2

  ul
    margin: 0
    padding: 0
    /* For IE, the outcast */
    zoom: 1
    *display: inline

    li
      border-bottom: 3px inset $white
      float: left
      padding: 0px 1.5rem
      list-style: none

      a 
        text-decoration: none
        line-height: 41px
        font-size: 14px
        font-weight: 300
        color: $secondary

      &:hover, &.active
        a 
          color: $primary

      &.active
        border-bottom: 3px inset $primary